<template>
    <div class="main aftermarket">
        <simple-header :userInfo="userInfo" />
        <div class="msg-wrapper">
            <breadcrumbs :breadcrumbs-data="[{ name: '个人中心', url: '/my' }, { name: '消息中心', url: '' }]" />
            <section class="msg-title">消息中心</section>
            <section v-for="item in msgList" :key="item.news_id" class="msg-item" :class="{ 'msg-important': item.isread==='0' }">
                <h3 class="msg-item-tit">
                    <i v-if="item.isread==='0'" class="msg-item-icon" />
                    <span class="msg-item-txt">违规信息</span>
                </h3>
                <div class="msg-item-toolbar">{{ item.addtime }}</div>
                <p class="msg-content">{{ item.content }}</p>
            </section>
            <section v-if="!msgList[0]" class="nodata">
                <p class="content">暂无数据</p>
            </section>
        </div>
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import breadcrumbs from '@c/breadcrumbs'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { newsList } from '@api/my'
// import '../mock'
export default {
    name: 'MyMessage',
    mixins: [ mixin, authMixin ],
    components: {
        simpleHeader,
        simpleFooter,
        breadcrumbs
    },
    data() {
        return {
            msgList: []
        }
    },
    created() {
        this.newsList()
        this.getAccount()
    },
    methods: {
        // 获取消息列表
        async newsList() {
            const res = await newsList()
            console.log('newsList res', res)
            if (res.code === 1001) {
                this.msgList = res.list.list
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.main.aftermarket {
    position: absolute;
    width: 100%;
    height: 100%;
}
.msg-wrapper {
    height: calc(100% - 135px);
}
.msg-title {
    margin-bottom: 30px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    border-top: 1px solid $grayest;
    background-color: $white;
}
.msg-item-tit {
    margin-bottom: 10px;
    font-weight: normal;
    color: $black;
    > * {
        @include inlineBlock();
    }
}
.msg-item-icon {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background-color: $red;
    border-radius: 50%;
}
.msg-item-toolbar {
    position: absolute;
    top: 20px;
    right: 20px;
}
.msg-content {
    padding: 0 20px;
}
.msg-item {
    position: relative;
    margin: 10px auto;
    padding: 20px;
    width: 1200px;
    color: $light;
    background-color: $white;
    &.msg-important {
        .msg-item-tit {
            font-weight: bold;
        }
    }
}
.nodata {
    position: relative;
    height: calc(100% - 150px);
    > .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
